import React, { useEffect } from 'react';

function Performance (){
    useEffect(() => {
        const end = document.querySelector('.end');
        if (end) {
            end.scrollIntoView({ behavior: "smooth" });
        }
    }, []);
    return(
        <nav className='container'>
        <div className='quantum'>
            <h2>QUANTUM CAPITAL.</h2>
            <p>Our ambition is to be the most successful investment firm of all time. We strive to identify the highest and best uses of capital to generate superior long-term returns for the world’s preeminent public and private institutions.</p>
        </div>
        <div>
            <img src='/img/fotoprincipal.webp' alt='Foto' className='fotoprincipal' loading="lazy"/>
        </div>
        <p className='end'></p>
        <hr className='linea1'/>
        <div className='primeratabla'>
            <p className='primeratablatitulo'>2023 Net Asset Values and Returns</p>
            <p className='primeratablap'>CLICK HERE FOR DISCLAIMERS AND ADDITIONAL IMPORTANT INFORMATION</p>
        <table>
        <thead>
            <tr>
                <th>Main Market</th>
                <th>Security Type</th>
                <th>Industry</th>
                <th>Investment Category</th>
                <th>Company</th>
                <th>Stock Ticker</th>
                <th>Shares</th>
                <th>Avg Cost (Per Share)</th>
                <th>Last Price</th>
                <th>Dividends Collected</th>
                <th>Realized Gain/Loss</th>
                <th>Total Gain/Loss</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>US</td><td>Bond</td><td>n.a</td><td>Dividend</td><td>US Treasury 3 Month Bills</td><td>TBIl</td><td>4000</td><td>$49.94</td><td>$50.02</td><td>$0.00</td><td>0.16%</td><td>$200,080.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Energy</td><td>Value</td><td>Iberdrola</td><td>BME:IBE</td><td>38400</td><td>$8.73</td><td>$11.05</td><td>$22,656.00</td><td>33.33%</td><td>$446,976.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Fund</td><td>Value</td><td>Berkshire Hathway</td><td>BRKB</td><td>1000</td><td>$230.00</td><td>$404.95</td><td>$0.00</td><td>76.07%</td><td>$404,950.00</td>
            </tr>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Bank</td><td>Growth</td><td>Grupo Financiero Galicia</td><td>GGAL</td><td>25000</td><td>$11.53</td><td>$17.11</td><td>$0.00</td><td>48.40%</td><td>$427,750.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Tech</td><td>Growth</td><td>Apple Inc</td><td>AAPL</td><td>1350</td><td>$126.30</td><td>$175.84</td><td>$1,269.00</td><td>39.97%</td><td>$238,653.00</td>
            </tr>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Oil & Gas</td><td>Growth</td><td>YPF Soicedad Anonima</td><td>YPF</td><td>12000</td><td>$10.88</td><td>$17.47</td><td>$0.00</td><td>60.57%</td><td>$209,640.00</td>
            </tr>
            <tr>
                <td>Brazil</td><td>Equity</td><td>Oil & Gas</td><td>Dividend</td><td>Petroleo Brasileiro S.A</td><td>PBR</td><td>12000</td><td>$11.66</td><td>$15.97</td><td>$13,440.00</td><td>46.57%</td><td>$205,080.00</td>
            </tr>
            <tr>
                <td>Peru</td><td>Equity</td><td>Bank</td><td>Dividend</td><td>Credicorp LTD</td><td>BAP</td><td>750</td><td>$132.50</td><td>$156.30</td><td>$0.00</td><td>17.96%</td><td>$117,225.00</td>
            </tr>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Bank</td><td>Growth</td><td>Banco Macro S.A</td><td>SAB.MC</td><td>3550</td><td>$23.30</td><td>$29.10</td><td>$0.00</td><td>24.89%</td><td>$103,305.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Bank</td><td>Growth</td><td>Banco de Sabadell S.A</td><td>IBM</td><td>100000</td><td>$1.23</td><td>$1.32</td><td>$6,000.00</td><td>12.20%</td><td>$138,000.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Industrial</td><td>Dividend</td><td>Arcosa Inc</td><td>ACA</td><td>4500</td><td>$58.01</td><td>$83.51</td><td>$900.00</td><td>44.30%</td><td>$376,695.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Energy</td><td>Growth</td><td>Fluidra S.A</td><td>FDR.MC</td><td>12000</td><td>$14.94</td><td>$19.56</td><td>$0.00</td><td>30.92%</td><td>$234,720.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Energy</td><td>Growth</td><td>Compania de Distribucion Integral Logista Holdings</td><td>LOG.MC</td><td>11000</td><td>$22.76</td><td>$24.20</td><td>$21,780.00</td><td>15.03%</td><td>$287,980.00</td>
            </tr>
            <tr className='ultimafila'>
                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>36.19%</td><td>$3.457.099</td>
            </tr>
        </tbody>
    </table>
        </div>
        <div className='primeratabla'>
            <p className='primeratablatitulo'>2022 Net Asset Values and Returns</p>
            <p className='primeratablap'>CLICK HERE FOR DISCLAIMERS AND ADDITIONAL IMPORTANT INFORMATION</p>
            <table>
        <thead>
            <tr>
                <th>Main Market</th>
                <th>Security Type</th>
                <th>Industry</th>
                <th>Investment Category</th>
                <th>Company</th>
                <th>Stock Ticker</th>
                <th>Shares</th>
                <th>Avg Cost (Per Share)</th>
                <th>Last Price</th>
                <th>Dividends Collected</th>
                <th>Realized Gain/Loss</th>
                <th>Total Gain/Loss</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Retail</td><td>Growth</td><td>Mercado Libre</td><td>Meli</td><td>200.0</td><td>$1,325.00</td><td>$1,348.00</td><td>$0.00</td><td>1.74%</td><td>$269,600.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Energy</td><td>Value</td><td>Iberdrola</td><td>BME:IBE</td><td>28600.0</td><td>$8.73</td><td>$10.08</td><td>$14,872.00	</td><td>21.42%	</td><td>$303,160.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Fund</td><td>Value</td><td>Berkshire Hathway</td><td>BRKB</td><td>650.0</td><td>$230.00</td><td>$318.90</td><td>$0.00</td><td>38.65%</td><td>$207,285.00</td>
            </tr>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Bank</td><td>Growth</td><td>Grupo Financiero Galicia</td><td>GGAL</td><td>24000.0</td><td>$8.20</td><td>$11.10</td><td>$5,040.00</td><td>37.93%	</td><td>$271,440.00</td>
            </tr>
            <tr>
                <td>Brazil</td><td>Equity</td><td>Oil & Gas</td><td>Dividend</td><td>Petroleo Brasileiro S.A</td><td>PBR</td><td>10000.0</td><td>$10.95</td><td>$16.05</td><td>$33,100.00</td><td>76.80%</td><td>$193,600.00</td>
            </tr>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Oil & Gas</td><td>Growth</td><td>YPF Soicedad Anonima</td><td>YPF</td><td>25000.0</td><td>$3.82</td><td>$9.09</td><td>$0.00</td><td>137.96%</td><td>$227,250.00</td>
            </tr>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Oil & Gas</td><td>Growth</td><td>Pampa Energia S.A</td><td>PAM</td><td>6000.0</td><td>$20.31</td><td>$29.95</td><td>$0.00</td><td>47.46%</td><td>$179,700.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Construction Materials</td><td>Dividend</td><td>Gerdau SA</td><td>GGB</td><td>14000.0</td><td>$5.90</td><td>$6.02</td><td>$9,380.00</td><td>13.39%</td><td>$93,660.00</td>
            </tr>
            <tr>
                <td>Peru</td><td>Equity</td><td>Bank</td><td>Dividend</td><td>Credicorp Ltd</td><td>BAp</td><td>1400.0</td><td>$124.91</td><td>$156.98</td><td>$9,128.00</td><td>30.89%</td><td>$228,900.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Tech</td><td>Dividend</td><td>International Business Machines</td><td>IBM</td><td>12000.0</td><td>$8.15</td><td>$10.80</td><td>$79,080.00</td><td>113.37%	</td><td>$208,680.00</td>
            </tr>
            <tr>
                <td>Brazil</td><td>Equity</td><td>Paper and Forest Products</td><td>Value</td><td>Suzano SA</td><td>SZ</td><td>12000.0</td><td>$8.61</td><td>$10.32</td><td>$0.00</td><td>19.86%</td><td>$123,840.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Healthcare</td><td>Value</td><td>Johnson & Johnson</td><td>JNJ</td><td>400.0</td><td>$174.84</td><td>$177.32</td><td>$1,780.00</td><td>3.95%</td><td>$72,708.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Financial Services</td><td>Value</td><td>Intercorp Fincnial Services</td><td>IFS</td><td>1500.0</td><td>$22.01</td><td>$22.98</td><td>$0.00</td><td>4.41%</td><td>$34,470.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>n/a</td><td>Dividend</td><td>Cash and Treasuries</td><td>n/a</td><td>n/a</td><td>n/a</td><td>n/a</td><td>n/a</td><td>0.00%</td><td>$115,987.00</td>
            </tr>
            <tr className='ultimafila'>
                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>38.01%</td><td>$2.682.660</td>
            </tr>
        </tbody>
    </table>
        </div>
        <div className='primeratabla'>
            <p className='primeratablatitulo'>2021 Net Asset Values and Returns</p>
            <p className='primeratablap'>CLICK HERE FOR DISCLAIMERS AND ADDITIONAL IMPORTANT INFORMATION</p>
            <table>
        <thead>
            <tr>
                <th>Main Market</th>
                <th>Security Type</th>
                <th>Industry</th>
                <th>Investment Category</th>
                <th>Company</th>
                <th>Stock Ticker</th>
                <th>Shares</th>
                <th>Avg Cost (Per Share)</th>
                <th>Last Price</th>
                <th>Dividends Collected</th>
                <th>Realized Gain/Loss</th>
                <th>Total Gain/Loss</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Tech</td><td>Growth</td><td>Mercado Libre</td><td>Meli</td><td>200.0</td><td>$1,300.00</td><td>$1,348.00</td><td>$0.00</td><td>3.69%</td><td>$269,600.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Energy</td><td>Value</td><td>Iberdrola</td><td>BME:IBE</td><td>16500.0</td><td>$8.73</td><td>$10.08</td><td>$8,745.00</td><td>21.53%</td><td>$175,065.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Telecommunications</td><td>Value</td><td>Berkshire Hathway</td><td>BRKB</td><td>350.0</td><td>$230.00</td><td>$273.40</td><td>$0.00</td><td>18.87%</td><td>$95,690.00</td>
            </tr>
            <tr>
                <td>Argentina</td><td>Equity</td><td>Bank</td><td>Growth</td><td>Grupo Financiero Galicia</td><td>GGAL</td><td>16000.0</td><td>$8.20</td><td>$11.10</td><td>$5,040.00</td><td>37.93%	</td><td>$271,440.00</td>
            </tr>
            <tr>
                <td>Spain</td><td>Equity</td><td>Energy</td><td>Value</td><td>Acciona Energías Renovables</td><td>BME:ANE</td><td>1700.0</td><td>$29.39</td><td>$27.52</td><td>$561.00</td><td>-5.24%</td><td>$47,345.00</td>
            </tr>
            <tr>
                <td>Brazil</td><td>Equity</td><td>Oil & Gas</td><td>Value</td><td>Ultrapar Participações</td><td>UGP</td><td>20000.0</td><td>$3.41</td><td>$3.76</td><td>$1,400.00</td><td>12.32%</td><td>$76,600.00</td>
            </tr>
            <tr>
                <td>Netherlands</td><td>Equity</td><td>Industrial</td><td>Value</td><td>ArcelorMittal</td><td>MT</td><td>3000.0</td><td>$22.90</td><td>$32.08</td><td>$1,140.00</td><td>41.75%</td><td>$97,380.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Bank</td><td>Value</td><td>Intercorp Financial Services</td><td>IFS</td><td>1500.0</td><td>$22.07</td><td>$27.91</td><td>$2,700.00</td><td>34.62%</td><td>$44,565.00</td>
            </tr>
            <tr>
                <td>Brazil</td><td>Equity</td><td>Bank</td><td>Value</td><td>Itau Unibanco Holdings S.A</td><td>ITUB</td><td>12000.0</td><td>$4.45</td><td>$5.56</td><td>$1,440.00</td><td>27.64%</td><td>$68,160.00</td>
            </tr>
            <tr>
                <td>US</td><td>Equity</td><td>Bank</td><td>Value</td><td>JP Morgan Chase & CO</td><td>JPM</td><td>725.0</td><td>$137.69</td><td>$163.04</td><td>$2,755.00</td><td>21.17%</td><td>$120,959.00</td>
            </tr>
            <tr>
                <td>US</td><td>Bond</td><td>n/a</td><td>Dividend</td><td>Cash and Treasuries</td><td>n/a</td><td>n/a</td><td>n/a</td><td>n/a</td><td>n/a</td><td>4.41%</td><td>$34,470.00</td>
            </tr>
            <tr className='ultimafila'>
                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>17.61%</td><td>$1.283.065</td>
            </tr>
        </tbody>
    </table>
        </div>
        </nav>
    );
}

export default Performance;
