import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from './Pantallas/Home';
import Press from './Pantallas/Press';
import Performance from './Pantallas/Performance';
import QuantumCapitalLtd from './Pantallas/QuantumCapitalLtd';
import InvestmentManager from './Pantallas/InvestmentManager';
import MobileNavbar from './Componentes/MobileNavbar/MobileNavbar';
import Footer from './Componentes/Footer/Footer';
import Header from './Componentes/Header/Header';

function App() {
  const screenWidth = window.innerWidth;
  return (
    <div className="App">
       {screenWidth < 1024 ? <MobileNavbar /> : <Header />}
      <Switch>
        <Route path='/' exact={true} component={Home}/>
        <Route path='/press' component={Press}/>
        <Route path='/performance' component={Performance}/>
        <Route path='/quantumcapitalltd' component={QuantumCapitalLtd}/>
        <Route path='/investmentmanager' component={InvestmentManager}/>
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
