import React from 'react';
import {Link} from 'react-router-dom';

function Header() {
  


  return (
    <header className='headerpc'>
      <Link to='/'> <img src='/img/logoblanco.png' alt="Logo Quantum Capital" className='logoblancopc'></img> </Link>
      <nav className='navbar'>
      <ul>
            <li><Link to='/' > HOME </Link></li>
            <li><Link to='/#' > PERFORMANCE <img width="10" height="10" src="https://img.icons8.com/external-creatype-glyph-colourcreatype/64/external-caret-miscellaneous-user-interface-v1-creatype-glyph-colourcreatype-4.png" alt="4"/></Link>
              <ul>
                <li>
                    <Link to='/performance'>Net Asset Value and Returns</Link>
                </li>
              </ul>
            </li>
            <li><Link to='/press' > PRESS RELEASES </Link></li>
            <li><Link to='/#'> ABOUT US <img width="10" height="10" src="https://img.icons8.com/external-creatype-glyph-colourcreatype/64/external-caret-miscellaneous-user-interface-v1-creatype-glyph-colourcreatype-4.png" alt="4"/></Link>
              <ul>
                <li>
                  <Link to='/quantumcapitalltd'>Quantum Capital Global Investment LP</Link>
                </li>
                <li className='investment'>
                  <Link to='/investmentmanager'>Investment Manager</Link>
                </li>
              </ul>
            </li>
          </ul>
      </nav>
    </header>
  );
}

export default Header;