import React, {useState} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';

function Home (){
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const db = firebase.firestore();
      await db.collection('usuarios').add({
        email,
      });
      setEmail('');
      console.log('Datos guardados en Firestore.');
    } catch (error) {
      console.error('Error al guardar los datos:', error);
    }
  };

    return(
        <nav className='container'>
        <div className='quantum'>
            <h2>QUANTUM CAPITAL.</h2>
            <p>Our ambition is to be the most successful investment firm of all time. We strive to identify the highest and best uses of capital to generate superior long-term returns for the world’s preeminent public and private institutions.</p>
        </div>
        <div className='cajafoto'>
            <img src='/img/fotoprincipal.webp' alt='Foto' className='fotoprincipal' loading="lazy"/>
        </div>
        
        <div className='quantum1'>
        <hr className='linea'/>
            <h3>Company Overview</h3>
            <p>Quantum Capital Global Investments LP ("Quantum Capital" or "Quantum") is a leading investment firm specializing in acquiring stakes in a select group of high-potential, large-capitalization companies across various industries. Quantum Capital's mission is to achieve superior long-term capital appreciation by leveraging its expertise in identifying undervalued companies. <pre/> 
            Quantum Capital began its operations as a private investment firm. <pre/>
            The Group has appointed Quantum Capital Investment Group LLC ("QCGI”, "the Investment Manager") as its primary investment manager. QCIG is tasked with the strategic management of Quantum Capital's portfolio, adhering to a disciplined investment philosophy that prioritizes sustainable growth and long-term value creation. The Investment Manager operates under the guidance of the Group's Board of Directors, ensuring alignment with Quantum Capital's investment objectives and policies. <pre/>
            Quantum Capital's investment portfolio is predominantly concentrated on 10 to 15 core holdings, primarily consisting of liquid, publicly traded large-capitalization companies in Latin America and selected global markets. The Investment Manager employs a rigorous analysis process to identify companies with strong fundamentals, compelling valuation, and potential for significant improvement in operational efficiency, corporate governance, and shareholder returns. Quantum Capital aims to limit risk through meticulous selection and portfolio diversification. Additionally, Quantum Capital may implement hedging strategies to protect against market volatility and capture unique investment opportunities, further aligning with Quantum Capital's strategic goal of maximizing shareholder value.
            </p>
        </div>
        
        <div className='quantum2'>
        <hr className='linea'/>
            <h3>Our Focus</h3>
        </div>
        <div className='yoga'>
            <img src='/img/chicayoga.webp' alt='Foto' className='chicayoga' loading="lazy"/>
            <div className='letras'>
                <p className='ptitulo'>Financial Well-being</p>
                <p>We aim to help hundreds invest for life, facing growing retirement saving challenges. How can we expand our impact, guiding more to secure their financial futures?</p>
            </div>
        </div>
        <div className='yoga'>
            <div className='letrasderecha'>
                <p className='ptitulo1'>Investment Access</p>
                <p>We simplify and lower the cost of investing. How can we leverage our expertise to enable a greater number of individuals to invest?</p>
            </div>
            <img src='/img/callewall.webp' alt='Foto' className='chicayoga' loading="lazy"/>
        </div>
        <div className='yoga'>
            <img src='/img/edificios.webp' alt='Foto' className='chicayoga' loading="lazy"/>
            <div className='letras'>
                <p className='ptitulo'>Resilient Economies</p>
                <p>Our goal is to enable an increasing number of individuals to achieve financial well-being, by providing our clients with a range of options to fulfill their investment objectives.</p>
            </div>
        </div>
        <div className='quantum3'>
        <hr className='linea'/>
            <h3>Contact Us</h3>
            <p>Reach out to <b> Quantum Capital</b> for innovative investment solutions. Email: <a href='mailto:contact@quantumcapitalg.com?Subject=Buenos%20días!%20'>contact@quantumcapitalg.com</a> or call us at <a href="https://wa.me/91128428258/?text=Hola, como estas?" target="_blank" rel="noreferrer">(+54) 9 11 2842-8258</a>.</p>
        </div>
        <div className='divformulario'>
            <div className='register'>
                <p>REGISTER FOR ALERTS</p>
                <p>Sign up to be notified of important updates.</p>
            </div>
            <div className='mail'>
                <p>EMAIL</p>
                <form onSubmit={handleSubmit}>  
                    <input type="text" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className='inputemail'/>
                    <button type="submit" className='boton' onSubmit={handleSubmit}>SUBMIT</button>
                </form>
            </div>
        </div>
        </nav>
    );
}

export default Home;