import React, { useEffect } from 'react';

function Press (){
    useEffect(() => {
        const end = document.querySelector('.end');
        if (end) {
            end.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return(
        <nav className='padre'>
        <div className='quantum'>
            <h2>QUANTUM CAPITAL.</h2>
            <p>Our ambition is to be the most successful investment firm of all time. We strive to identify the highest and best uses of capital to generate superior long-term returns for the world’s preeminent public and private institutions.</p>
        </div>
        <div>
            <img src='/img/fotoprincipal.webp' alt='Foto' className='fotoprincipal' loading="lazy"/>
        </div>
        <p className='end'></p>
        <hr  className='linea2'/>
        <div>
            <p className='press'>2024 Press Releases</p>
        </div>
        
        <div className='peranuncio'>
            <p className='anuncio'>Announcement</p><p>Date</p>
        </div>
        <div className='seganuncio'>
            <p className='anuncio'>Announcement</p><p>Date</p>
        </div>
        <div className='peranuncio'>
            <p className='anuncio'>Announcement</p><p>Date</p>
        </div>
        <div className='seganuncio'>
            <p className='anuncio'>Announcement</p><p>Date</p>
        </div>
        <div className='peranuncio'>
            <p className='anuncio'>Announcement</p><p>Date</p>
        </div>
        <div className='seganuncio'>
            <p className='anuncio'>Announcement</p><p>Date</p>
        </div>
        </nav >
    );
}

export default Press;