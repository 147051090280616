import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCTkNeHtbtCDZCU1Itc84VunV_pLltFRpQ",
  authDomain: "quantumcapital-c5c56.firebaseapp.com",
  projectId: "quantumcapital-c5c56",
  storageBucket: "quantumcapital-c5c56.appspot.com",
  messagingSenderId: "958412052039",
  appId: "1:958412052039:web:0405dcbf8874cb66cdc362"
};
  
  // Inicializa Firebase con la configuración
  firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();

  export { firebase, db };



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
  <App />
</BrowserRouter>,
document.getElementById('root')
);
