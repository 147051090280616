import React, { useState } from 'react';
import {Link} from 'react-router-dom';

function MobileNavbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const closeMenu = () => {setMenuOpen(false);};
  const [aboutMenuOpen, aboutSetMenuOpen] = useState(false);

  return (
    <header>
      <nav className='cajapadreheader'>
        <div className='divlogoheader'>
            <Link to='/'> <img src='/img/logoblanco.png' alt="Logo Quantum Capital" className='logoblanco'></img> </Link>
        </div>
        <div className='boton3rayas'>
            <button onClick={() => setMenuOpen(!menuOpen)} className='button-menu'>
              <img width="20" height="20" src="https://img.icons8.com/ios/50/menu--v1.png" alt="menu--v1"/>
            </button>
        </div>
      </nav>
      {menuOpen && (
        <nav className='acaestoy'>
          <ul>
            <li><Link to='/' onClick={closeMenu}> HOME </Link></li>
            <li
              onClick={() => setSubmenuOpen(!submenuOpen)}>
              <Link to='#'>PERFORMANCE <img width="10" height="10" src="https://img.icons8.com/external-creatype-glyph-colourcreatype/64/external-caret-miscellaneous-user-interface-v1-creatype-glyph-colourcreatype-4.png" alt="4"/></Link>
              {submenuOpen && (
                <ul className='submenu'>
                  <li>
                    <Link to='/performance' onClick={closeMenu}>Net Asset Value and Returns</Link>
                  </li>
                </ul>
              )}
            </li>
            <li><Link to='/press' onClick={closeMenu}> PRESS RELEASES </Link>  </li>
            <li onClick={() => aboutSetMenuOpen(!aboutMenuOpen)}>
              <Link to='#'> ABOUT US <img width="10" height="10" src="https://img.icons8.com/external-creatype-glyph-colourcreatype/64/external-caret-miscellaneous-user-interface-v1-creatype-glyph-colourcreatype-4.png" alt="4"/></Link>
              {aboutMenuOpen &&(
                <ul className='submenu'>
                <li>
                  <Link to='/quantumcapitalltd' onClick={closeMenu}>Quantum Capital Global Investment LP</Link>
                </li>
                <li>
                  <Link to='/investmentmanager' onClick={closeMenu}>Investment Manager</Link>
                </li>
              </ul>
              )}
              </li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default MobileNavbar;