import {Link} from 'react-router-dom';

function Footer() {

  return (
        <nav className='footer'>
            <div className='logofooter'>
                <Link to='/'> <img src='/img/logonegro.png' alt="Logo Quantum Capital" className='logonegro'></img> </Link>
            </div>
            <div className='parrafofooter'>
                <p>2024 Quantum Capital.</p>
            </div>
        </nav>
  );
}

export default Footer;