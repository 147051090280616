import React, { useEffect } from 'react';

function InvestmentManager (){
    useEffect(() => {
        const end = document.querySelector('.end');
        if (end) {
            end.scrollIntoView({ behavior: "smooth" });
        }
    }, []);
    return(
        <nav className='container'>
        <div className='quantum'>
            <h2>QUANTUM CAPITAL.</h2>
            <p>Our ambition is to be the most successful investment firm of all time. We strive to identify the highest and best uses of capital to generate superior long-term returns for the world’s preeminent public and private institutions.</p>
        </div>
        <div className='cajafoto'>
            <img src='/img/fotoprincipal.webp' alt='Foto' className='fotoprincipal' loading="lazy"/>
        </div>
        <p className='end'></p>
        <hr className='linea1'/>
        <div className='ltd'>
            <h3>Quantum Capital Global Investment LP</h3>
            <p>Quantum Capital Global Investments LP, a closed-ended investment fund based in Delaware, focuses on acquiring stakes in large-cap companies to enhance long-term shareholder value. Since its inception, Quantum has aimed at strategic investments in high-quality businesses, managed by Quantum Capital Investment Group LLC. 
                <pre/>
                Quantum's registered address is:
                <pre/>
                16192 Coastal Highway,<br/>
                Lewes, Delaware 19958, <br/>
                United States.</p>
        </div>
        <div className='ltd1'>
            <p><b>QCIG Documents</b></p>
            <p className='subrayado'>Form ADV, Part 2A and Part 2B, Dated March 2023 (PLACEHOLDER TEXT)</p>
            <p className='subrayado'>Approval granted by the European Financial Markets Authority on October 10, 2014. (PLACEHOLDER TEXT)</p>
            <p className='subrayado'>SEC Order Granting Registration (PLACEHOLDER TEXT)</p>
        </div>
        <div className='ltd2'>
            <p><b>QCIG Reports</b></p>
            <p className='subrayado'>2020 Financial Statements</p>
            <p className='subrayado'>2021 Financial Statements</p>
            <p className='subrayado'>2022 Financial Statements</p>
        </div>
        </nav>
    );
}

export default InvestmentManager;
