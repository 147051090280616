import React, { useEffect } from 'react';

function QuantumCapitalLtd (){
    useEffect(() => {
        const end = document.querySelector('.end');
        if (end) {
            end.scrollIntoView({ behavior: "smooth" });
        }
    }, []);
    return(
        <nav className='container'>
        <div className='quantum'>
            <h2>QUANTUM CAPITAL.</h2>
            <p>Our ambition is to be the most successful investment firm of all time. We strive to identify the highest and best uses of capital to generate superior long-term returns for the world’s preeminent public and private institutions.</p>
        </div>
        <div className='cajafoto'>
            <img src='/img/fotoprincipal.webp' alt='Foto' className='fotoprincipal' loading="lazy"/>
        </div>
        <p className='end'></p>
        <hr className='linea'/>
        <div className='quantumau'>
            <h3>About Us.</h3>
            <p>Quantum Capital Global Investments LP and its affiliates (“QCIG”) manage private investment funds using a value-based philosophy with a fundamental bottoms-up approach to analyzing each investment. We focus primarily on emerging markets, investing on public and private markets across corporate credit, structured credit, real estate, and equity securities. The fund focuses on Argentinian, Brazilan , Chilean, Spain and North American markets. 
            <pre/>
            Quantum Capital Global Investments LP was legally reestablished as a limited partnership under the laws of Delaware on April 11, 2024, as a registered private fund.
            <pre/>
            For its investment management activities, Quantum has engaged Quantum Capital Investment Group LLC ("QCIG" or "the Investment Manager"), an entity founded on the principles of strategic investment in high-quality businesses believed to have limited downside and capable of generating predictable, recurring cash flows. Under the strategic direction and supervision of Quantum's Board of Directors, QCIG is tasked with the prudent investment of the company's assets and liabilities, aligning with Quantum's investment policy
            <pre/>
            The majority of Quantum's investment portfolio is deliberately allocated across 10 to 14 core holdings, predominantly in liquid, listed large capitalization companies primarily based on Latin and North America. The Investment Manager employs a focused approach towards identifying companies that exhibit strong business fundamentals. Additionally, Quantum and its Investment Manager employ strategic risk management techniques, including the judicious selection of investments and portfolio construction, and potentially utilizing hedging strategies to mitigate market-related risks and capitalize on asymmetric investment opportunities.</p>

            </div>
        </nav>
    );
}

export default QuantumCapitalLtd;
